@import "@/styles/_variables.scss";











































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.icon-image {
  width: 85px;
  height: 48px;
}
.icon-control {
  height: 20px; 
  width: 20px;
  cursor: pointer;
}
.icon-drag {
  height: 20px; 
  width: 20px;
  cursor: move;
}
.uk-badge {
  height: 11px; 
  min-width: 11px;
}
.icon-image,.name,.type,.access,.icon,.back-link, .edit-icon {
  cursor: pointer;
}
.controls, .content-title {
  color: var(--app-primary-color);
  word-break: break-all;
}
.triangle-down {
 position: absolute;
 margin: 10px;
 right: 0;
}
.new-collection-btn {
  padding: 0 20px;
}
.uk-dropdown {
  min-width: 150px;
}
.close:hover {
  cursor: pointer;
  text-decoration: none;
  color: #000;
}
.close {
  vertical-align: middle;
  display: flex;
  color: #a8a8a8;
  cursor: pointer;
}
.selected {
  background-color: var(--app-primary-color);
}
.uk-table-divider>:first-child>tr:not(:first-child), .uk-table-divider>:not(:first-child)>tr, .uk-table-divider>tr:not(:first-child) {
  border-bottom: 1px solid var(--border-color);
}
.trash-icon {
  height: 20px; 
  width: 20px;
  cursor: pointer;
}

.uk-text-truncate {
  color:var(--app-primary-color);
  cursor:pointer;
}
.uk-text-truncate:hover {
  color:var(--text-color);
}
